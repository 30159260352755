<template>
    <div class="d-flex justify-center pa-md-5 mx-lg-auto">
        <v-card class="d-flex justify-center mb-6" v-if="personal" style="max-width: 600px" elevation="2">
            <v-form class="pa-md-4 mx-lg-auto" ref="form" style="min-width: 350px" v-model="valid" lazy-validation>
                <v-autocomplete v-model="form.vendedora" :rules="vendedoraRules" :items="personal"
                    label="Vendedor"></v-autocomplete>
                <v-autocomplete v-model="form.personalCargo" :rules="personalCargoRules" :items="personal"
                    label="Personal a Cargo"></v-autocomplete>
                <v-autocomplete v-if="!$route.query.idVenta" v-model="form.mainProduct" :rules="mainProductRules"
                    :items="_listaProductosOut" item-text="rename" item-value="id" label="Producto a Equipar" return-object>
                    <template v-slot:item="data">
                        <v-chip small color="secondaryTheme" class="caption px-2" dark>{{ data.item.internal_id }}</v-chip>
                        <v-chip v-if="data.item.idCodigo" small color="green" class="caption px-2" dark>{{
                            data.item.idCodigo }}</v-chip>
                        <span class="caption">{{ data.item.rename }}
                        </span>
                    </template>
                </v-autocomplete>
                <!--                 <v-autocomplete v-model="form.posicion" :rules="posicionRules" :items="items" label="Posicion del Motor"></v-autocomplete>-->
                <form autocomplete="nope" @submit.prevent="postData">
                    <v-container grid-list-md fluid>
                        <v-row dense align="center">
                            <v-col cols="12" sm="12">
                                <v-autocomplete dense v-model="servicio" v-validate="'required'" label="Producto"
                                    :items="_listaProductosOut" data-vv-as="marca"
                                    :error-messages="errors.collect('servicio')" data-vv-name="servicio" required
                                    item-text="rename" item-value="id" return-object>
                                    <template v-slot:item="data">
                                        <v-chip small color="secondaryTheme" class="caption px-2" dark>{{ data.item.internal_id }}</v-chip>
                                        <v-chip v-if="data.item.idCodigo" small color="green" class="caption px-2" dark>{{
                                            data.item.idCodigo }}</v-chip>
                                        <span class="caption">{{ data.item.rename }}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field dense v-model="servicio.unidades" :rules="unidadesRules" label="Unidades"
                                    @keypress="isNumber" />
                            </v-col>

                            <v-col cols="12" sm="6" class="text-center">
                                <v-btn block color="secondaryTheme" dark type="submit">agregar</v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-container>
                </form>
                <v-col cols="12" class="grey lighten-5">
                    <v-data-table multi-sort :headers="headers" :items="_productos" class="elevation-1" :items-per-page="20"
                        item-key="timestamp">
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green"
                                        @click="upItem(item)">mdi-swap-vertical</v-icon>
                                </template>
                                <span>SUBIR</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on" small
                                        @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <span>ELIMINAR</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea v-model="form.observaciones" name="input-7-1" label="Observaciones" value=""></v-textarea>
                </v-col>
                <v-btn :disabled="!valid" :loading="loading" color="success" class="mr-4" @click="validate"> Generar
                </v-btn>
            </v-form>
        </v-card>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { formatNumber } from '@/utils/genericUtils'

export default {
    filters: {},
    props: ['lista', 'service'],
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            servicio: DefaultForm.formSelectServicio(),
            number: '',
            loadingSync: false,
            dataVenta: '',
            dataProducto: '',
            loading: false,
            valid: true,
            vendedoras: '',
            valordelItem: '',
            personalCargo: '',
            vendedoraRules: [(v) => !!v || 'Vendedora is required'],
            personalCargoRules: [(v) => !!v || 'personal is required'],
            servicioRules: [(v) => !!v || 'servicio is required'],
            mainProductRules: [(v) => !!v || 'Producto Principal is required'],
            unidadesRules: [(v) => !!v || 'unidad is required'],
            select: null,
            checkbox: false,
            items: ['IZQUIERDA', 'DERECHA'],
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Descripción', value: 'description' },
                { text: 'Codigo', value: 'internal_id' },
                { text: 'Marca', value: 'model' },
                { text: 'Unidades', value: 'unidades' }
            ],
            form: {
                vendedora: '',
                observaciones: '',
                personalCargo: '',
                posicion: ''
            }
        }
    },
    watch: {
        productos: {
            deep: true,
            handler(newList) {
                if (newList.length === 0) {
                    this.listaServicios = []
                } else {
                    this.listaServicios = newList
                }
            }
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),

        idTipoMoneda() {
            return this.servicio.idTipoMoneda || 1
        },
        ...mapState('users', ['personal', 'listaUser']),

        ...mapState('ventas', ['datos', 'modalReserva', 'productos']),
        ...mapState('productos', ['listaProductos', 'dataproducto']),
        ...mapState('inventario', ['dataproductoWarehouse', 'inventory']),

        _listaProductosOut() {
            let listaProductos = [];
            if (this.dataproductoWarehouse) {
                const uniqueInternalIds = new Set(); // Crear un conjunto para almacenar internal_ids únicos
                this.dataproductoWarehouse.forEach((v) => {
                    // Verificar si el internal_id no está en el conjunto, agregarlo a la lista y al conjunto
                    if (!uniqueInternalIds.has(v.internal_id)) {
                        listaProductos.push({
                            ...v,
                            rename: `${v.description} | ${v.internal_id}`,
                            price: v.sale_unit_price,
                            idTipoMoneda: v.currency_type_id == 'USD' ? 2 : 1,
                            marca: v.brandName ? v.brandName : '',
                            medida: v.medida ? v.medida.name : '',
                            color: v.color ? v.color : '',

                            idTipoMonedaPurchase: v.currency_type_id == 'USD' ? 2 : 1,

                        });
                        uniqueInternalIds.add(v.internal_id);
                    }
                });
            }

            return listaProductos;
        },

        idProducto() {
            return this.servicio.id
        },
        _productos() {
            return this.productos
        },
        itemTotalProduct() {
            let val = {
                price: 0,
                subTotal: 0
            }
            const { descuentos, comision, price, unidades } = this.servicio
            if (this.servicio) {
                val.subTotal = (this._parseFloat(price) + this._parseFloat(comision)) * parseInt(unidades) - this._parseFloat(descuentos).toFixed(2)
                val.price = this._parseFloat(price) + this._parseFloat(comision)
            }
            return {
                price: val.price,
                subTotal: val.subTotal
            }
        }
    },

    async mounted() {
        await Promise.all([
            this.getItemWarehouses(),
            this.$store.dispatch('users/getPersonas')
        ]);
        console.log('this.listaUser', this.listaUser)
        console.log('this.datos', this.datos)
        //console.log('hello', this.$route.query)
        //console.log('datos', this.datos)
        if (this.$route.query.idVenta) {
            this.form.vendedora = this.listaUser.find((x) => x.id == this.datos.idColaborador)?.nombres
            const obtenerVenta = await this.$store.dispatch('ventas/ventaProducto', {
                id: this.$route.query.idVenta
            })
            //console.log('obtenerVenta', obtenerVenta)
            let obtenerProducto = this.dataproducto.find((x) => x.id === Number(this.$route.query.idProducto))
            //console.log('obtenerProductov1', obtenerProducto)
            if (!obtenerProducto) {
                const _obtenerProducto = typeof obtenerVenta.productos == 'string' ? JSON.parse(obtenerVenta.productos) : obtenerVenta.productos
                obtenerProducto = _obtenerProducto.find((x) => x.id === Number(this.$route.query.idProducto))
            }
            // }
            //console.log('obtenerProductov2', obtenerProducto)
            this.dataVenta = obtenerVenta
            this.dataProduct = obtenerProducto
            //console.log('this.dataProducto', this.dataProduct)
        }
    },
    methods: {
        ...mapActions({
            getItemWarehouses: 'inventario/getItemWarehouses',

        }),
        upItem(newItem) {
            const position = this.productos.findIndex((x) => x.timestamp == newItem.timestamp)
            //console.log('position ', position)
            const lista = Object.assign([], this.productos)
            if (position >= 1) {
                const oldItem = { ...lista[position - 1], timestamp: Date.now() }
                ////console.log('lista ', lista)
                lista.splice(position, 1, oldItem)
                ////console.log('lista 1', lista)
                lista.splice(position - 1, 1, newItem)
                ////console.log('lista ', lista)
                //colors.splice(2, 0, 'purple');

                this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            }
        },
        deleteItem(item) {
            // //console.log(item);
            let lista = this.productos
            lista = lista.filter((v) => v.timestamp != item.timestamp)
            this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
            //this.listaServicios = lista
        },
        isformatNumber(item, name) {
            const value = item[name]
            return formatNumber(value, item.idTipoMoneda)
        },
        async sincronizarProductos() {
            this.loadingSync = true
            //  await this.$store.dispatch('productos/getLista', { online: true })
            this.loadingSync = false
        },
        _parseFloat(val) {
            return parseFloat(val) || 0
        },
        async validate() {
            let found = []
            const valFinalAccesorios = this._productos
            found = valFinalAccesorios.filter((element) => (element.description.includes('Tina ') ? false : true))

            const validacion = await this.$refs.form.validate()
            var today = new Date()
            var dd = today.getDate()
            var mm = today.getMonth() + 1 //January is 0!
            var yyyy = today.getFullYear()

            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            today = yyyy + '-' + mm + '-' + dd
            console.log('fecha', today)
            console.log('validacion', validacion)
            const numeroPedido = Number(this.$route.query.idVenta)
            console.log('numeroPedido', numeroPedido)
            console.log('this.dataProduct', this.dataProduct)
            console.log('this.form', this.form)
            const { mainProduct } = this.form
            if (validacion) {
                // this.loading = true

                const crearOrdenEquipamiento = await this.$store.dispatch('ventas/crearOrdenEquipamiento', {
                    vendedor: this.form.vendedora,
                    personalCargo: this.form.personalCargo,
                    fechaEmision: today,
                    ordenPedido: numeroPedido,
                    producto: this.dataProduct?.description || this._productos[0].description,//mainProduct?.description,
                    model: this.dataProduct?.marca || mainProduct?.marca,
                    medida: this.dataProduct?.medida || mainProduct?.medida,
                    color: this.dataProduct?.color || mainProduct?.color,
                    posicion: '-',
                    observaciones: this.form.observaciones,
                    items: found
                    // items: valFinalAccesorios
                })

                //console.log('OrdenDeProduccion', crearOrdenEquipamiento)
                //console.log('this._productos', this._productos)
                //console.log('valFinalAccesorios', valFinalAccesorios)
                await this.$store.dispatch('reporte/reporteOrdenEquipamiento', {
                    orden: crearOrdenEquipamiento.data.id,
                    vendedor: this.form.vendedora,
                    fecha: today,
                    ordenpedido: numeroPedido,
                    personacargo: this.form.personalCargo,
                    producto: this.dataProduct?.description || mainProduct?.description,
                    model: this.dataProduct?.marca || mainProduct?.marca,
                    medida: this.dataProduct?.medida || mainProduct?.medida,
                    color: this.dataProduct?.color || mainProduct?.color,
                    posicion: this.form.posicion,
                    observacion: this.form.observaciones,
                    items: found
                })
                this.loading = false

                this.$router.push(`/logistica/orden-equipamiento/lista`)
            }
        },
        postData() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    let lista = []
                    const listaServicios = []
                    const servicio = {
                        ...this.servicio,
                        priceOrigen: this.servicio.price,
                        price: this.itemTotalProduct.price,
                        subTotal: this.itemTotalProduct.subTotal,
                        timestamp: Date.now()
                    }
                    //console.log('servicio ', servicio)
                    const tipoCambio = servicio.idTipoMoneda === 1 ? 1 : null
                    this.$store.commit('ventas/UPDATE_DATOS', {
                        idTipoMoneda: servicio.idTipoMoneda,
                        tipoCambio
                    })

                    listaServicios.push(servicio)
                    lista = this.productos.concat(listaServicios)
                    this.$store.commit('ventas/UPDATE_PRODUCTOS', lista)
                    this.servicio = DefaultForm.formSelectServicio()
                }
            })
        }
    }
}
</script>
